import logo from './logo.svg';
import './App.css';
import React, { useEffect, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

function Box(props:any) {
  // This reference will give us direct access to the THREE.Mesh object
  const ref = useRef()
  // Set up state for the hovered and active state
  // Subscribe this component to the render-loop, rotate the mesh every frame
  useFrame((state, delta) => {});
  // Return the view, these are regular Threejs elements expressed in JSX
  return (
    <>
      <mesh
        {...props}
        ref={ref}>
        <boxGeometry args={[.02, .02, 500]} />
        <meshStandardMaterial color={'lightblue'} />
      </mesh>
      <Pulse x={props.position[0]} y={props.position[1]} />
      <Pulse x={props.position[0]} y={props.position[1]} />
      <Pulse x={props.position[0]} y={props.position[1]} />
      <Pulse x={props.position[0]} y={props.position[1]} />
      <Pulse x={props.position[0]} y={props.position[1]} />
      <Pulse x={props.position[0]} y={props.position[1]} />
    </>
  )
}
interface XY {
  x : number,
  y : number,
}

const Pulse = (offset : XY) => {
  const [z, setZ] = useState(0);
  useEffect(() => {
    setZ(Math.random() * 400);
  }, [])

  useFrame((state, delta) => {
    setZ(z + 4);
  });
  return (
    <pointLight position={[offset.x,offset.y,100-z%400]} intensity={1} distance={5} />
  )
}

function App() {
  return (
    <div className="App">
      <div className="title">
        <span className="fiber">Fiber</span>
        <span className="stake">Stake</span>
        <span className="optic">Optic</span>
      </div>
      <Canvas className="three">

        {/* <pointLight position={[10, 10, 10]} /> */}

        <Box position={[-7, -2, 0]} />
        <Box position={[-5, -2, 0]} />
        <Box position={[-3, -2, 0]} />
        <Box position={[-1, -2, 0]} />
        <Box position={[1, -2, 0]} />
        <Box position={[3, -2, 0]} />
        <Box position={[5, -2, 0]} />
        <Box position={[7, -2, 0]} />
      </Canvas>
    </div>
  );
}

export default App;
